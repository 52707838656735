import React, { useState } from "react"
import SearchEngineOptimizer from "../components/seo"
import "./index.scss"

const GMapsCompoenent = () => {
  // TODO : arabic language if arabic
  return (
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7756.838267222056!2d-2.933176898590938!3d35.17285942309843!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd77a7faf5c5044f%3A0x12d1905e5e48d002!2s305%20Ave%20Des%20Far%2C%20Nador!5e0!3m2!1sen!2sma!4v1633191369595!5m2!1sen!2sma" width="600" height="500" allowFullScreen="" loading="lazy" title="Office Map"></iframe>
  )
};

const FrenchContent = (props) => {
  let sectionClasses = ["container"]
  if (props.hide === "true")
    sectionClasses.push("is-hidden")
  const sectionFinalClass = sectionClasses.join(" ")

  return (
    <section className={sectionFinalClass}>
      <div className="columns">
        <div className="content is-half hero-body column box information">
          <h2 className="title is-4">Dr. Evelyne ESCOFFIER (ep. Dr. EL JARRARI)</h2>
          <h3 className="subtitle is-4 has-text-grey-dark"> Psychiatre </h3>
          <ul>
            <li> Etudes Médicales (1er, 2ème et 3ème cycles) à la Faculté de Médecine de St. Antoine (Paris, France) </li>
            <li> Spécialité de Psychiatrie (Diplôme d'État de Spécialité) à la Faculté de Médecine de Tours (France) </li>
          </ul>
          <hr />
          <h2 className="title is-4">Contact</h2>
          <ul>
            <li>Consultation sur rendez-vous. Contacter le numéro WhatsApp 07.77.21.14.94 pour la prise de rendez-vous. Par message ou audio uniquement.</li>
            <li>
              <span>Horaires de travail</span>
              <ul>
                <li> Du lundi au vendredi de 08h à 15h </li>
                <li> Le samedi de 08h à 13h </li>
              </ul>
            </li>

            <li>Adresse : 305 avenue des FAR, 2nd étage, Nador</li>
            <li>Tél : 05.36.33.44.66</li>
          </ul>
        </div>
        <div className="content is-half hero-body column">
          <h2>Cabinet : voir le repère rouge</h2>
          <GMapsCompoenent />
        </div>
      </div>
    </section>
  )
}

const ArabicContent = (props) => {
  let sectionClasses = ["container"]
  if (props.hide === "true")
    sectionClasses.push("is-hidden")
  const sectionFinalClass = sectionClasses.join(" ")

  return (
    <section className={sectionFinalClass}>
      <div className="columns">
        <div className="content is-half hero-body column box information" dir="rtl">
          <h2 className="title is-4" >الدكتورة إيفلين إسكوفيي (زوجة الدكتور الجراري)</h2>
          <h4 className="subtitle is-4 has-text-grey-dark"> طبيبة مختصة في الأمراض العقلية و النفسية و الأمراض العصبية</h4>
          <ul>
            <li> دبلوم الطب (السلك الأول و الثاني و الثالث) من كلية الطب سانت أنطوان (باريس، فرنسا) </li>
            <li>تخصص الطب النفسي (دبلوم دولة) في كلية الطب بتور (فرنسا)</li>
          </ul>
          <hr />
          <h2 className="title is-4">للمزيد من المعلومات :</h2>

          <ul>
          <li> الفحص الطبي : بالميعاد. الإتصال بال WhatsApp بالرقم 07.77.21.14.94 لتسجيل الميعاد. إستعمال الرسائل المكتوبة و ألأوديو. </li>
          <li> <span>أوقات العمل : </span>
            <ul>
            <li className="sub"> من الإثنين إلى الجمعة : من 8 صباحا إلى 15 بعد الزوال  </li>
            <li className="sub"> يوم السبت  : من 8 صباحا إلى 13 بعد الزوال   </li>
            </ul>
          </li>
            <li>شارع الجيش الملكي الرقم 305 الطابق 2 الناظور</li>
            <li>الهاتف  : 05.36.33.44.66</li>
          </ul>
        </div>
        <div className="content is-half hero-body column">
          <h2 dir="rtl">مكان العيادة : العلامة الحمراء  </h2>
          <GMapsCompoenent />
        </div>
      </div>
    </section>
  )
}

const Content = (props) => {
  if(props.lng === "ar")
    return <ArabicContent hide="false" />
  else
    return <FrenchContent hide="false" />
};

const IndexPage = () => {
  const DEFAULT_LNG = "fr";
  const [lng, setLng] = useState(DEFAULT_LNG);
  return (
    <main>
      <SearchEngineOptimizer />
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <btn className="navbar-item">
            <span></span>
          </btn>
        </div>
        <div id="navbarBasicExample">
          <div className="navbar-start">
            <btn onClick={() => setLng("fr")} className={ "navbar-item is-tab" + (lng === "fr" ? " is-active" : "") }>Français</btn>
            <btn onClick={() => setLng("ar")} className={ "navbar-item is-tab" + (lng === "ar" ? " is-active" : "") }>العربية</btn>
          </div>
        </div>
      </nav>
      <Content lng={lng} />
    </main>
  )
}

export default IndexPage

